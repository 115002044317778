/* eslint-disable canonical/filename-match-exported */
import React from 'react'
import { Container, Layout } from '../components'
import Profile from '../components/content/Profile'
import Head from '../services/Head'

const AwsContact = () => (
  <>
    <span id='s3-t-firma'>Amazon Web Services EMEA SARL</span>
    <br />
    <span id='s3-t-strasse'>38 avenue John F. Kennedy,</span>
    <br />
    <span id='s3-t-plz'>L-1855</span> <span id='s3-t-ort'>Luxembourg</span>
  </>
)

const Privacy = () => {
  return (
    <div className='overflow-x-hidden'>
      <Head />
      <Layout hideSidebarOnMobile sidebarContent={<Profile />}>
        <div className='px-3 py-10 sm:px-6 sm:py-12'>
          <Container className='w-full space-y-14'>
            <div>
              <h1 className='mb-12 headline'>Datenschutzerklärung</h1>

              <div className='prose text-content'>
                <h4>Allgemeiner Hinweis und Pflichtinformationen</h4>
                <p>
                  Die verantwortliche Stelle für die Datenverarbeitung auf
                  dieser Website ist:
                </p>
                <p>
                  <span id='s3-t-firma'>Lorenz Nimmervoll</span>
                  <br />
                  <span id='s3-t-strasse'>Forstnerweg, 12</span>
                  <br />
                  <span id='s3-t-plz'>4112</span>{' '}
                  <span id='s3-t-ort'>St. Gotthard im Mühlkreis</span>
                </p>
                <p>
                  Die verantwortliche Stelle entscheidet allein oder gemeinsam
                  mit anderen über die Zwecke und Mittel der Verarbeitung von
                  personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).
                </p>
                <h4>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h4>
                <p>
                  Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge
                  der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits
                  erteilten Einwilligung ist jederzeit möglich. Für den Widerruf
                  genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit
                  der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom
                  Widerruf unberührt.
                </p>
                <h4>
                  Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde
                </h4>
                <p>
                  Als Betroffener steht Ihnen im Falle eines
                  datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der
                  zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde
                  bezüglich datenschutzrechtlicher Fragen ist der
                  Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der
                  Sitz unseres Unternehmens befindet.
                </p>
                <h4>Recht auf Datenübertragbarkeit</h4>
                <p>
                  Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer
                  Einwilligung oder in Erfüllung eines Vertrags automatisiert
                  verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die
                  Bereitstellung erfolgt in einem maschinenlesbaren Format.
                  Sofern Sie die direkte Übertragung der Daten an einen anderen
                  Verantwortlichen verlangen, erfolgt dies nur, soweit es
                  technisch machbar ist.
                </p>
                <h4>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</h4>
                <p>
                  Sie haben jederzeit im Rahmen der geltenden gesetzlichen
                  Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre
                  gespeicherten personenbezogenen Daten, Herkunft der Daten,
                  deren Empfänger und den Zweck der Datenverarbeitung und ggf.
                  ein Recht auf Berichtigung, Sperrung oder Löschung dieser
                  Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema
                  personenbezogene Daten können Sie sich jederzeit über die im
                  Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.
                </p>
                <h4>SSL- bzw. TLS-Verschlüsselung</h4>
                <p>
                  Aus Sicherheitsgründen und zum Schutz der Übertragung
                  vertraulicher Inhalte, die Sie an uns als Seitenbetreiber
                  senden, nutzt unsere Website eine SSL-bzw.
                  TLS-Verschlüsselung. Damit sind Daten, die Sie über diese
                  Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen
                  eine verschlüsselte Verbindung an der „https://“ Adresszeile
                  Ihres Browsers und am Schloss-Symbol in der Browserzeile.
                </p>
                {/* <h4>Server-Log-Dateien</h4>
                <p>
                  In Server-Log-Dateien erhebt und speichert der Provider der
                  Website automatisch Informationen, die Ihr Browser automatisch
                  an uns übermittelt. Dies sind:
                </p>
                <div className='not-prose'>
                  <ul>
                    <li>Besuchte Seite auf unserer Domain</li>
                    <li>Datum und Uhrzeit der Serveranfrage</li>
                    <li>Browsertyp und Browserversion</li>
                    <li>Verwendetes Betriebssystem</li>
                    <li>Referrer URL</li>
                    <li>Hostname des zugreifenden Rechners</li>
                    <li>IP-Adresse</li>
                  </ul>
                </div>
                <p>
                  Es findet keine Zusammenführung dieser Daten mit anderen
                  Datenquellen statt. Grundlage der Datenverarbeitung bildet
                  Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur
                  Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen
                  gestattet.
                </p> */}
                <h4>Hosting</h4>
                <p>
                  Sofern Sie sich als Besucher weder registrieren noch
                  einloggen, erheben wir in sog. Logfiles folgende Daten, die
                  Ihr Browser übermittelt:
                </p>
                <p>
                  IP-Adresse, Datum und Uhrzeit der Anfrage, Zeitzonendifferenz
                  zur Greenwich Mean Time, Inhalt der Anforderung,
                  HTTP-Statuscode, übertragene Datenmenge, Website, von der die
                  Anforderung kommt und Informationen zu Browser und
                  Betriebssystem.
                </p>
                <p>
                  Das ist erforderlich, um unsere Website anzuzeigen und die
                  Stabilität und Sicherheit zu gewährleisten. Dies entspricht
                  unserem berechtigten Interesse im Sinne des Art. 6 Abs. 1 S. 1
                  lit. f DSGVO. Wir setzen für die Zurverfügungstellung unserer
                  Website folgenden Hoster ein.
                </p>
                <p>
                  <AwsContact />
                </p>
                <p>
                  Dieser ist Empfänger Ihrer personenbezogenen Daten und als
                  Auftragsverarbeiter für uns tätig. Dies entspricht unserem
                  berechtigten Interesse im Sinne des Art. 6 Abs. 1 S. 1 lit. f
                  DSGVO, selbst keinen Server in unseren Räumlichkeiten
                  vorhalten zu müssen.
                </p>
                <p>
                  <p>
                    Weitere Informationen zu Widerspruchs- und
                    Beseitigungsmöglichkeiten gegenüber Amazon Web Services
                    finden Sie unter:{' '}
                    <a className='break-all link' href='https://aws.amazon.com'>
                      https://aws.amazon.com
                    </a>
                  </p>
                  <p>
                    Sie haben das Recht der Verarbeitung zu widersprechen. Ob
                    der Widerspruch erfolgreich ist, ist im Rahmen einer
                    Interessenabwägung zu ermitteln.
                  </p>

                  <p>
                    Die Verarbeitung der unter diesem Abschnitt angegebenen
                    Daten ist weder gesetzlich noch vertraglich vorgeschrieben.
                    Die Funktionsfähigkeit der Website ist ohne die Verarbeitung
                    nicht gewährleistet.
                  </p>
                </p>
                <h4>Technische Hinweise</h4>
                <p>
                  Neben den Server-Logfiles können auch von der verwendeten
                  Applikation und deren Plugins personenbezogene Daten
                  verarbeitet werden. Darunter fallen u.a. die Protokollierung
                  fehlerhafter Anmeldeversuche, oder Zugriffe auf nicht
                  existierende Seiten (404).
                  <p>
                    Dies sollte überprüft und entsprechend ergänzt werden. Im
                    Falle eine Speicherung, sollte ebenfalls angegeben werden,
                    wie lange diese erfolgt und ob und ab wann eine
                    Anonymisierung der erhobenen Daten stattfindet.
                  </p>
                </p>
                <h4>Rechtliche Hinweise</h4>
                <p>
                  Grundsätzlich ist ein Auftragsverarbeitungsvertrag mit dem
                  Hoster abzuschließen. Das bayerische Landesamt für
                  Datenschutzaufsicht hat für das Hosting rein statischer
                  Websites eine Ausnahme gemacht. Für den Fall, dass die
                  Webseite der Selbstdarstellung dient, z.B. von Vereinen oder
                  Kleinunternehmen, keine personenbezogenen Daten an den
                  Betreiber fließen und kein Tracking stattfindet, liegt keine
                  Auftragsverarbeitung vor. Weiter heißt es: „Die Tatsache, dass
                  auch beim Hosting von statischen Webseiten zwangsläufig
                  IP-Adressen, d.h. personenbezogene Daten, verarbeitet werden
                  müssen, führt nicht zur Annahme einer Auftragsverarbeitung.
                  Das wäre nicht sachgerecht. Die (kurzfristige)
                  IP-Adressenspeicherung ist vielmehr noch der
                  TK-Zugangsvermittlung des Website-Hosters nach dem TKG
                  zuzurechnen und dient in erster Linie Sicherheitszwecken des
                  Hosters.“ Es sollte deshalb überprüft werden, ob der Hoster
                  Tracking und Auswertungstools zur Verfügung stellt und ob und
                  wie lange Logfiles aufbewahrt werden.
                </p>
                <h4>Weiter Zwecke der Datenverarbeitung</h4>
                <div className='not-prose'>
                  <ul>
                    {' '}
                    <li>
                      Gewährleistung der Stabilität und Sicherheit der Website
                    </li>{' '}
                    <li>Auswertung der Systemsicherheit und -Stabilität</li>{' '}
                    <li>Optimierung der Website</li>{' '}
                    <li>
                      Überprüfung, ob rechtswidrige Nutzung stattgefunden hat
                    </li>{' '}
                  </ul>
                </div>
                <h4>Widerspruchs- und Beseitigungsmöglichkeit</h4>
                <p>
                  Der häufig verwendete Hinweis, dass seitens des Nutzers keine
                  Widerspruchsmöglichkeit bestehe, entspricht nicht der
                  gesetzlichen Vorgabe. Wird die Verarbeitung auf das
                  berechtigte Interesse des Verantwortlichen gestützt (Art. 6
                  Abs. 1 lit.f DSGVO), so ist das Recht auf Widerspruch nicht
                  per se ausgeschlossen. Ob dieser jedoch Erfolg hat, ist im
                  Rahmen einer Interessenabwägung zu ermitteln. Auch wenn in der
                  Praxis das berechtigte Interesse des Websitebetreibers wohl
                  überwiegen wird, folgt daraus kein Ausschluss des
                  Widerspruchrechts. Eine solche Formulierung sollte korrigiert
                  werden, da sie dazu führen kann, dass der Betroffene an der
                  Ausübung seines Widerspruchrechts gehindert wird.
                </p>
                <h4>Empfänger</h4>
                <p>
                  Gemäß Art. 13. Abs. 1 lit. e DSGVO, besteht die Pflicht „die
                  Empfänger oder Kategorien von Empfängern der personenbezogenen
                  Daten“ anzugeben. Häufig wird vertreten, dass vorrangig
                  Empfänger namentlich und mit Anschrift zu benennen sind und
                  nur hilfsweise auf Kategorien zurückgegriffen werden darf.
                  Eine andere Auffassung vertritt ein Wahlrecht zwischen der
                  namentlichen Nennung und der Angabe von Kategorien. (Vgl.
                  Daum: Pflichtangaben auf Webseiten MMR 2020 643 (646) m.w.N.)
                  Demnach wäre es ausreichend als Kategorie „Hoster“ anzugeben.
                  Für diese Auffassung spricht jedoch, wenn überhaupt, nur die
                  Übersichtlichkeit. Dem Sinn und Zweck der Vorschrift
                  entspricht es aber vielmehr den Namen und die Anschrift
                  anzugeben, zumal dieser im Rahmen des Hostings bereits
                  feststeht (Vgl. Lorenz: Datenschutzrechtliche
                  Informationspflichten (VuR 2019, 213 (216)).
                </p>
                <h4>Speicherdauer</h4>
                <p>
                  Für die Feststellung der Speicherdauer sollten die Server- und
                  Applikationseinstellungen überprüft werden, auch um
                  Widersprüche zwischen den angebenden Zwecken zu vermeiden. So
                  kann es beispielsweise zu Unstimmigkeiten kommen, wenn
                  angegeben wird, dass nach jeder Sitzung die Daten gelöscht
                  werden, diese aber gleichzeitig der Stabilität und Sicherheit
                  dienen sollen. Eine allgemeine Mitteilung, die Daten würden so
                  lange gespeichert werden, wie es für die angegebenen Zwecke
                  erforderlich ist, ist nicht ausreichend (Vgl.
                  Simitis/Hornung/Spiecker gen. Döhmann, Datenschutzrecht, Art.
                  13 Rn 15). Ausreichend ist aber gem. Art. 13 Abs. 2 lit a.
                  DSGVO die Angabe von Kriterien für die Festlegung der
                  Speicherdauer.
                </p>
                <h4>Amazon Cloudfront</h4>
                <p>
                  Wir nutzen das Content Delivery Network (CDN) Amazon
                  CloudFront von Amazon Web Services EMEA SARL, 38 avenue John
                  F. Kennedy, L-1855 Luxembourg (AWS), um die Sicherheit und die
                  Auslieferungsgeschwindigkeit unserer Website zu erhöhen. Dies
                  entspricht unserem berechtigten Interesse (Art. 6 Abs. 1 lit.
                  f DSGVO). Ein CDN ist ein Netzwerk aus weltweit verteilten
                  Servern, das in der Lage ist, optimiert Inhalte an den
                  Websitenutzer auszuliefern. Für diesen Zweck können
                  personenbezogene Daten in Server-Logfiles von AWS verarbeitet
                  werden. Bitte vergleichen Sie die Ausführungen unter
                  „Hosting“.
                </p>
                {/* [Zusätzlich bewahren wir [anonymisierte] Logfiles
                  auf, um die Stabilität und Sicherheit unserer Website zu
                  gewährleisten [optimieren].] */}
                <p>
                  AWS ist Empfänger Ihrer personenbezogenen Daten und als
                  Auftragsverarbeiter für uns tätig. Die entspricht unserem
                  berechtigten Interesse im Sinne des Art. 6 Abs. 1 S. 1 lit. f
                  DSGVO, selbst kein Content Delivery Network zu betreiben.
                </p>
                <p>
                  Sie haben das Recht der Verarbeitung zu widersprechen. Ob der
                  Widerspruch erfolgreich ist, ist im Rahmen einer
                  Interessenabwägung zu ermitteln.
                </p>
                <p>
                  Die Verarbeitung der unter diesem Abschnitt angegebenen Daten
                  ist weder gesetzlich noch vertraglich vorgeschrieben. Die
                  Funktionsfähigkeit der Website ist ohne die Verarbeitung nicht
                  gewährleistet.
                </p>
                <p>
                  Ihre personenbezogenen Daten werden von AWS so lange
                  gespeichert, wie es für die beschriebenen Zwecke erforderlich
                  ist.
                  {/* [Wir löschen die gespeicherten Logfiles nach [ ] ]. */}
                </p>
                <p>
                  Weitere Informationen zu Widerspruchs- und
                  Beseitigungsmöglichkeiten gegenüber AWS finden Sie unter:{' '}
                  <a
                    className='break-all link'
                    href='https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice__German_Translation.pdf'
                  >
                    https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice__German_Translation.pdf
                  </a>
                </p>
                <p>
                  AWS hat Compliance-Maßnahmen für internationale
                  Datenübermittlungen umgesetzt. Diese gelten für alle
                  weltweiten Aktivitäten, bei denen AWS personenbezogene Daten
                  von natürlichen Personen in der EU verarbeitet. Diese
                  Maßnahmen basieren auf den EU-Standardvertragsklauseln (SCCs).
                  Weitere Informationen finden Sie unter:{' '}
                  <a
                    className='break-all link'
                    href='https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf'
                  >
                    https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf
                  </a>
                </p>
                <h4>Cookies</h4>
                <p>
                  Unsere Website verwendet Cookies. Das sind kleine Textdateien,
                  die Ihr Webbrowser auf Ihrem Endgerät speichert. Cookies
                  helfen uns dabei, unser Angebot nutzerfreundlicher, effektiver
                  und sicherer zu machen.{' '}
                </p>
                <p>
                  Einige Cookies sind “Session-Cookies.” Solche Cookies werden
                  nach Ende Ihrer Browser-Sitzung von selbst gelöscht. Hingegen
                  bleiben andere Cookies auf Ihrem Endgerät bestehen, bis Sie
                  diese selbst löschen. Solche Cookies helfen uns, Sie bei
                  Rückkehr auf unserer Website wiederzuerkennen.
                </p>
                <p>
                  Mit einem modernen Webbrowser können Sie das Setzen von
                  Cookies überwachen, einschränken oder unterbinden. Viele
                  Webbrowser lassen sich so konfigurieren, dass Cookies mit dem
                  Schließen des Programms von selbst gelöscht werden. Die
                  Deaktivierung von Cookies kann eine eingeschränkte
                  Funktionalität unserer Website zur Folge haben.
                </p>
                <p>
                  Das Setzen von Cookies, die zur Ausübung elektronischer
                  Kommunikationsvorgänge oder der Bereitstellung bestimmter, von
                  Ihnen erwünschter Funktionen (z.B. Warenkorb) notwendig sind,
                  erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als
                  Betreiber dieser Website haben wir ein berechtigtes Interesse
                  an der Speicherung von Cookies zur technisch fehlerfreien und
                  reibungslosen Bereitstellung unserer Dienste. Sofern die
                  Setzung anderer Cookies (z.B. für Analyse-Funktionen) erfolgt,
                  werden diese in dieser Datenschutzerklärung separat behandelt.
                </p>
                <h4>Google Analytics</h4>
                <p>
                  Unsere Website verwendet Funktionen des Webanalysedienstes
                  Google Analytics. Anbieter des Webanalysedienstes ist die
                  Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
                  94043, USA.
                </p>
                <p>
                  Google Analytics verwendet "Cookies." Das sind kleine
                  Textdateien, die Ihr Webbrowser auf Ihrem Endgerät speichert
                  und eine Analyse der Website-Benutzung ermöglichen. Mittels
                  Cookie erzeugte Informationen über Ihre Benutzung unserer
                  Website werden an einen Server von Google übermittelt und dort
                  gespeichert. Server-Standort ist im Regelfall die USA.
                </p>
                <p>
                  Das Setzen von Google-Analytics-Cookies erfolgt auf Grundlage
                  von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website
                  haben wir ein berechtigtes Interesse an der Analyse des
                  Nutzerverhaltens, um unser Webangebot und ggf. auch Werbung zu
                  optimieren.
                </p>
                <p>IP-Anonymisierung</p>
                <p>
                  Wir setzen Google Analytics in Verbindung mit der Funktion
                  IP-Anonymisierung ein. Sie gewährleistet, dass Google Ihre
                  IP-Adresse innerhalb von Mitgliedstaaten der Europäischen
                  Union oder in anderen Vertragsstaaten des Abkommens über den
                  Europäischen Wirtschaftsraum vor der Übermittlung in die USA
                  kürzt. Es kann Ausnahmefälle geben, in denen Google die volle
                  IP-Adresse an einen Server in den USA überträgt und dort
                  kürzt. In unserem Auftrag wird Google diese Informationen
                  benutzen, um Ihre Nutzung der Website auszuwerten, um Reports
                  über Websiteaktivitäten zu erstellen und um weitere mit der
                  Websitenutzung und der Internetnutzung verbundene
                  Dienstleistungen gegenüber uns zu erbringen. Es findet keine
                  Zusammenführung der von Google Analytics übermittelten
                  IP-Adresse mit anderen Daten von Google statt.
                </p>
                <p>Browser Plugin</p>
                <p>
                  Das Setzen von Cookies durch Ihren Webbrowser ist
                  verhinderbar. Einige Funktionen unserer Website könnten
                  dadurch jedoch eingeschränkt werden. Ebenso können Sie die
                  Erfassung von Daten bezüglich Ihrer Website-Nutzung
                  einschließlich Ihrer IP-Adresse mitsamt anschließender
                  Verarbeitung durch Google unterbinden. Dies ist möglich, indem
                  Sie das über folgenden Link erreichbare Browser-Plugin
                  herunterladen und installieren:{' '}
                  <a href='https://tools.google.com/dlpage/gaoptout?hl=de'>
                    https://tools.google.com/dlpage/gaoptout?hl=de
                  </a>
                  .
                </p>
                <p>Widerspruch gegen die Datenerfassung</p>
                <p>
                  Sie können die Erfassung Ihrer Daten durch Google Analytics
                  verhindern, indem Sie auf folgenden Link klicken. Es wird ein
                  Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei
                  zukünftigen Besuchen unserer Website verhindert: Google
                  Analytics deaktivieren.
                </p>
                <p>
                  Einzelheiten zum Umgang mit Nutzerdaten bei Google Analytics
                  finden Sie in der Datenschutzerklärung von Google:{' '}
                  <a href='https://support.google.com/analytics/answer/6004245?hl=de'>
                    https://support.google.com/analytics/answer/6004245?hl=de
                  </a>
                  .
                </p>
                <p>Auftragsverarbeitung</p>
                <p>
                  Zur vollständigen Erfüllung der gesetzlichen
                  Datenschutzvorgaben haben wir mit Google einen Vertrag über
                  die Auftragsverarbeitung abgeschlossen.
                </p>
                <p>Demografische Merkmale bei Google Analytics</p>
                <p>
                  Unsere Website verwendet die Funktion “demografische Merkmale”
                  von Google Analytics. Mit ihr lassen sich Berichte erstellen,
                  die Aussagen zu Alter, Geschlecht und Interessen der
                  Seitenbesucher enthalten. Diese Daten stammen aus
                  interessenbezogener Werbung von Google sowie aus Besucherdaten
                  von Drittanbietern. Eine Zuordnung der Daten zu einer
                  bestimmten Person ist nicht möglich. Sie können diese Funktion
                  jederzeit deaktivieren. Dies ist über die
                  Anzeigeneinstellungen in Ihrem Google-Konto möglich oder indem
                  Sie die Erfassung Ihrer Daten durch Google Analytics, wie im
                  Punkt “Widerspruch gegen die Datenerfassung” erläutert,
                  generell untersagen.
                </p>
                <p>
                  <small className='block'>
                    Quelle: Datenschutz-Konfigurator von{' '}
                    <a
                      href='http://www.mein-datenschutzbeauftragter.de'
                      rel='noreferrer'
                      target='_blank'
                    >
                      mein-datenschutzbeauftragter.de
                    </a>
                  </small>
                  <small className='block'>
                    Quelle: Open Source Privacy{' '}
                    <a
                      href='https://opr.vc/'
                      rel='noreferrer noopener'
                      target='_blank'
                    >
                      opr.vc
                    </a>
                  </small>
                </p>
                <p>Zuletzt aktualisiert am 26.08.2022</p>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    </div>
  )
}

export default Privacy
